<template>
    <div class="tabs">
        <ul class="tabs__header">
            <li
                v-for="(tab, index) in tabs"
                :key="tab.title"
                class="tab_header-item"
                :class="{'tab__selected': (index == selectedIndex)}"
                @click="selectTab(index)"
            >
                {{ tab.title }}
            </li>
        </ul>
        <slot />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectedIndex: 0,
                tabs: []
            }
        },
        created() {
            this.tabs = this.$children
        },
        mounted() {
            this.selectTab(0)
        },
        methods: {
            selectTab(i) {
                this.selectedIndex = i

                this.tabs.forEach((tab, index) => {
                    tab.isActive = (index === i)
                })
                this.$emit('tab-changed', this.tabs[i])
            }
        }
    }
</script>

<style >
.tabs {
    width: 90%;
    max-width: 420px;
    margin: 0 auto;
}

.tab {
    display: inline-block;
    color: black;
    padding: 1.5rem 0 0;
    width: 100%;
}

.tabs .tab {
    color: var(--grey);
}

ul.tabs__header {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
    white-space: nowrap;
    overflow: auto;
    scrollbar-width: none;
}

ul.tabs__header::-webkit-scrollbar {
    display: none;
}

ul.tabs__header > li {
    padding: 0.5rem 0.75rem 0;
    margin: 0;
    display: inline-block;
    cursor: pointer;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
    transition: color 150ms ease-in, background 0.25s ease-in, font-weight 0.25s ease-in-out;
    color: var(--white);
    border-bottom: 1px solid var(--white);
    font-size: 1.3rem;
    width: 50%;
    font-weight: normal;
}

ul.tabs__header > li.tab__selected {
    color: var(--info-color);
    border-bottom: 1px solid var(--info-color);
    font-weight: bold;
}

@media screen and (min-width: 768px) {
    .tabs {
        width: 100%;
    }

    ul.tabs__header > li {
        padding: 0.5rem 0.75rem;
        font-size: 1.5rem;
    }

    .tab {
        padding: 2.5rem 0 0;
    }
}

</style>
